<template>
    <div>
        <div v-if="form.type" class="tw-mb-2 tw-border-0 tw-border-b tw-border-solid tw-border-gray-200">
            <div v-if="form.type === 'comment'" class="tw-pb-2">
                <tiny-editor
                    v-model="form.body"
                    placeholder="Write your comment here..."
                />
                <div v-if="form.errors.has('body')" class="tw-text-xs tw-text-red-400 tw-mt-2">
                    {{ form.errors.get('body') }}
                </div>
            </div>
            <div v-if="form.type === 'email'" class="tw-space-y-2">
                <v-autocomplete
                    v-model="form.from"
                    :items="mailboxes"
                    :error-messages="form.errors.get('from')"
                    @input="form.errors.clear('from')"
                    prefix="From:"
                    item-text="title"
                    item-value="id"
                    outlined
                    hide-details="auto"
                    dense
                >
                    <template v-slot:selection="data">
                        <div class="tw-text-sm">{{ data.item.from_name }} &lt;{{ data.item.from_address }}&gt;</div>
                    </template>
                    <template v-slot:item="data">
                        <template>
                            <v-list-item-content>
                                <v-list-item-title v-html="data.item.from_name"></v-list-item-title>
                                <v-list-item-subtitle v-html="data.item.from_address"></v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </template>
                </v-autocomplete>
                <v-autocomplete
                    v-model="form.to"
                    :items="recipientsOptions"
                    :error-messages="form.errors.get('to')"
                    @input="form.errors.clear('to')"
                    prefix="To:"
                    item-text="name"
                    item-value="id"
                    outlined
                    hide-details="auto"
                    dense
                    chips
                    deletable-chips
                    multiple
                >
                    <template v-slot:selection="data">
                        <v-chip
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            close
                            @click="data.select"
                            @click:close="removeTo(data.item)"
                            small
                            class="tw-mb-1"
                        >
                            {{ data.item.name }} &lt;{{ data.item.email }}&gt;
                        </v-chip>
                    </template>
                    <template v-slot:item="data">
                        <template>
                            <v-list-item-content>
                                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                <v-list-item-subtitle v-html="data.item.email"></v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </template>
                </v-autocomplete>
                <v-text-field
                    v-model="form.subject"
                    :error-messages="form.errors.get('subject')"
                    @input="form.errors.clear('subject')"
                    prefix="Subject:"
                    outlined
                    dense
                    hide-details="auto"
                />
                <div>
                    <div
                        class="tw-p-2 tw-rounded tw-border tw-border-solid"
                        :class="form.errors.has('body') ? 'tw-border-red-400' : 'tw-border-gray-400 hover:tw-border-gray-600'"
                    >
                        <tiny-editor
                            v-model="form.body"
                            placeholder="Write your reply here..."
                            @input="form.errors.clear('body')"
                        />
                    </div>
                    <div v-if="form.errors.has('body')" class="tw-text-xs tw-text-red-400 tw-mt-2">
                        {{ form.errors.get('body') }}
                    </div>
                </div>
                <v-file-input
                    v-model="form.attachments"
                    multiple
                    prefix="Attachments:"
                    placeholder="Click here to attach files..."
                    prepend-icon=""
                    show-size
                    counter
                    outlined
                    dense
                />
            </div>
        </div>
        <div class="tw-flex tw-items-center tw-justify-end">
            <v-btn
                small
                color="primary"
                @click="comment"
                :loading="commenting"
                class="tw-ml-2"
            >{{ form.type === 'comment' ? 'Comment' : 'Send' }}</v-btn>
        </div>
    </div>
</template>

<script>
import Form from '@/utils/form';
import Http from '@/utils/httpClient';

export default {
    components: {
        TinyEditor: () => import(/* webpackChunkName: "tiny-editor" */ '@/components/TinyEditor.vue'),
    },
    props: {
        mailboxes: {
            type: Array,
            required: true,
        },
        recipientsOptions: {
            type: Array,
            default() {
                return []
            }
        },
        ticket: {
            type: Object,
            required: true,
        },
        parent: {
            type: Object,
            default() {
                return null;
            }
        },
        type: {
            type: String,
            default: 'comment',
        }
    },
    watch: {
        'form.type'(type) {
            if (type) {
                this.$emit('commentFormOpened');
            }
        }
    },
    data() {
        return {
            commenting: false,
            form: new Form({
                from: null,
                to: [],
                subject: null,
                body: '',
                attachments: null,
                type: 'comment',
                parent_id: null,
                staff_only: false,
            })
        }
    },
    mounted() {
        const mailbox = this.mailboxes.find((mailbox) => mailbox.ticket_department_id === this.ticket.department_id) ?? this.mailboxes[0];

        this.form.set({
            from: mailbox ? mailbox.id : null,
            to: this.recipientsOptions.filter((option) => option.is_default === true)
                    .map((option) => option.id),
            subject: `[MDTK${this.ticket.number}] ${this.ticket.title}`,
            parent_id: this.parent ? this.parent.id : null,
            type: this.type,
        })
    },
    methods: {
        removeTo(item) {
            const index = this.form.to.findIndex((to) => to === item.id)
            if (index >= 0) this.form.to.splice(index, 1)
        },
        comment() {
            this.form.errors.clear();
            this.commenting = true;

            const form = new FormData();
            const data = this.form.payload();

            data.to = data.to.map((id) => {
                const recipient = this.recipientsOptions.find((r) => r.id === id);

                return {
                    id: recipient.id,
                    class: recipient.class,
                }
            });

            Object.keys(data).forEach((i) => {
                if (Array.isArray(data[i])) {
                    data[i].forEach((k, l) => {
                        if (Object.keys(k).length > 0) {
                            Object.keys(k).forEach((m) => {
                                form.append(`${i}[${l}][${m}]`, k[m]);
                            })
                        } else {
                            form.append(`${i}[${l}]`, k);
                        }
                    })
                }

                if (data[i] && !Array.isArray(data[i])) {
                    form.append(i, data[i]);
                }
            });

            Http.post(`tickets/${this.$route.params.ticketId}/comment`, form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                params: {
                    query: {
                        conversation: [
                            'id',
                            'title',
                            'description',
                            'created_at_with_time_for_human',
                            'creator',
                            'attachments',
                            'recipients',
                            'staff_only',
                        ],
                        conversation_creator: [
                            'id',
                            'name',
                            'id_with_class',
                        ],
                        conversation_item: [
                            'id',
                            'comment',
                            'parsed_comment',
                            'created_at_with_time_for_human',
                            'creator',
                            'attachments',
                            'recipients',
                            'staff_only',
                        ],
                        conversation_recipient: [
                            'id',
                            'name',
                            'email',
                        ],
                        attachment: [
                            'file_name',
                            'url',
                            'download_url',
                            'mime_type',
                            'foreign_id',
                            'is_inline',
                        ]
                    }
                }
            })
            .then(({ data }) => {
                this.commenting = false;

                if (this.parent) {
                    this.$store.commit('ticket/storeConversationReply', {
                        id: this.parent.id,
                        data,
                    })
                } else {
                    this.$store.commit('ticket/addConversation', data);
                }

                this.form.set({
                    body: '',
                    attachments: null,
                    type: null,
                });

                if (this.form.type === 'email') {
                    this.$root.$snackbar.open({
                        text: 'Email sent.',
                        type: 'success',
                    });
                }
            })
            .catch((e) => {
                this.commenting = false;

                if (e.response) {
                    this.form.errors.record(e.response.data);
                }
            })
        },
    },
}
</script>
